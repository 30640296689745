/** @jsx jsx */
import React from 'react'
import { jsx } from 'theme-ui'
import { VStack } from '../../styles/els'

const Newsletter = ({ large = true }) => {
  return <form action="https://synergytheatreproject.us14.list-manage.com/subscribe/post?u=8297ebcd6f0e17daa68b3010f&amp;id=438df3c77d&amp;f_id=000eb8e5f0" method="post" target="_blank">
    <VStack space={3}>
      <h4 sx={{ variant: large ? 'text.heading_25' : 'text.bold_20' }}>
        Sign up to our newsletter
      </h4>
      <VStack space={2}>
        <label sx={{ variant: large ? 'text.regular_20' : 'text.regular_12', color: 'white' }}>Please enter your email address</label>
        <input
          type="email"
          name="EMAIL"
          sx={{
            variant: 'text.mono',
            bg: 'grey3',
            color: 'white',
            border: 'none',
            px: 5,
            py: '8px',
            display: 'inline-block',
            borderRadius: '27px',
          }} />
      </VStack>
      <div>
        <input
          type="submit"
          name="subscribe"
          value="Subscribe"
          sx={{
            position: 'relative',
            variant: 'text.mono',
            borderWidth: '1px',
            borderStyle: 'solid',
            borderColor: 'white',
            backgroundColor: 'white',
            px: 5,
            py: '8px',
            display: 'inline-block',
            borderRadius: '27px',
            overflow: 'hidden',
            cursor: 'pointer',
          }} />
      </div>
    </VStack>
  </form>
}

export default Newsletter
